'use client'
import { useRef } from 'react'

import { CommonNinjaWidget } from 'commonninja-react'

interface Props {
  widgetId: string
}

export const CouponBar = ({ widgetId }: Props) => {
  const containerId = `coupon-bar-widget-${widgetId}`
  // const [isLoaded, setIsLoaded] = useState(false)
  // const [barHeight, setBarHeight] = useState<number | undefined>(0)
  const containerRef = useRef<HTMLDivElement | null>(null)
  // const shadowRootRef = useRef<ShadowRoot | null>(null)

  // const mutationObserverRef = useRef(
  //   hasWindow()
  //     ? new MutationObserver(() => {
  //         setBarHeight(shadowRootRef?.current?.querySelector('.notification-bar')?.getBoundingClientRect().height)
  //       })
  //     : null
  // )

  // useEffect(() => {
  //   if (isLoaded) {
  //     waitForElement({
  //       getEl: () => {
  //         const el = document.getElementById(containerId)?.firstElementChild
  //         return el?.shadowRoot as Node
  //       },
  //     }).then(el => {
  //       if (el) {
  //         shadowRootRef.current = el as ShadowRoot
  //         mutationObserverRef.current?.observe(el, {
  //           childList: true,
  //           subtree: true,
  //           attributes: true,
  //           attributeFilter: ['class', 'style'],
  //         })
  //       }
  //     })
  //   }
  // }, [containerId, isLoaded])

  return (
    <div ref={containerRef} id={containerId}>
      <CommonNinjaWidget widgetId={widgetId} />
    </div>
  )
}
